<template>
  <div>
    <div class="header">
      <h6>
        <span>提现管理</span>
        <el-switch class="switchStyle"
                   v-model="isAllWithDraw"
                   active-color="#3278EB"
                   @change="switchChange"
                   active-text="全局提现已开启"
                   inactive-color="#DADADA"
                   inactive-text="全局提现已关闭"
                   v-role="['administrator']">
        </el-switch>
      </h6>
      <div class="btn-wrap">
        <p class="btn"
           @click="exportClick"><i class="icon iconfont icon-daochu btn"></i><span>导出</span></p>
      </div>
    </div>
    <el-card style="margin-top:20px">
      <withdrawCard></withdrawCard>
      <el-form style="margin-top:33px"
               :inline="true"
               :size="'small'"
               :model="formInline"
               class="demo-form-inline"
               ref="form">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="主播经纪人：">
              <brokerSelect @clearBrokerSelect="clearBrokerSelect"
                            @selectTreeChange="selectTreeChange"
                            :placeholder="'请选择主播经纪人'"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="8"
                  v-role="['administrator']">
            <el-form-item label="经办人:">
              <belonger @belongerSeChange="belongerSeChange"></belonger>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审批状态："
                          prop="status">
              <el-select v-model="formInline.status"
                         clearable
                         placeholder="请选择审批状态">
                <el-option label="待审批"
                           value="0">
                </el-option>
                <el-option label="已打款"
                           value="1">
                </el-option>
                <el-option label="已拒绝"
                           value="2">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="8">
          <el-form-item label="收益类型："
                        prop="type">
            <el-select v-model="formInline.type"
                       clearable
                       placeholder="请选择收益类型">
              <el-option label="本月"
                         value="0">
              </el-option>
              <el-option label="上月"
                         value="1">
              </el-option>
              <el-option label="钱包"
                         value="2">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-row :gutter="20"
                style="margin-top:8px">
          <el-col :span="12">
            <el-form-item label="提现时间："
                          prop="broadcast_date">
              <el-date-picker v-model="formInline.broadcast_date"
                              type="daterange"
                              :clearable="false"
                              range-separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="searchClick"
                     icon="el-icon-search">搜索</el-button>
        </el-row>
      </el-form>
      <tablePage :tableData="tableData"
                 style="margin-top:30px"
                 :currentPage="formInline.page"
                 :height="650"
                 @sort-change="tableSortChange"
                 @current-change="currentChange"
                 v-loading="loading"
                 ref="tableList"
                 :total="total"
                 id="table">
        <el-table-column label="提现人信息"
                         min-width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.broker_name }}</p>
            <div class="status-wrap"
                 v-if="role(['administrator','manager'])"
                 v-show="scope.row.status === 0">
              <span @click="passClick(scope.row)"
                    class="pass">通过</span>
              <span @click="refuseClick(scope.row)"
                    class="refuse">拒绝</span>
            </div>
            <span class="pass_loading"
                  v-if="!role(['administrator','manager'])"
                  v-show="scope.row.status === 0">正在审核</span>
            <span class="pass_yet"
                  v-show="scope.row.status === 1">已打款</span>
            <span class="refuse_yet"
                  v-show="scope.row.status === 2">已拒绝</span>
          </template>
        </el-table-column>
        <el-table-column label="收益类型/金额"
                         min-width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.type }}</p>
            <span class="income">{{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column label="手续费"
                         prop="handling_cost"
                         min-width="120">
        </el-table-column>
        <el-table-column label="税率"
                         prop="tax_rate"
                         min-width="120">
        </el-table-column>
        <el-table-column label="支付宝号/身份证"
                         min-width="300">
          <template slot-scope="scope">
            <p>{{ scope.row.alipay }}</p>
            <span>{{scope.row.id_number}}</span>
          </template>
        </el-table-column>
        <el-table-column label="实际到账金额"
                         prop="actual_amount"
                         min-width="120">
        </el-table-column>
        <el-table-column label="提现时间"
                         :sortable="'custom'"
                         prop="created_at"
                         min-width="240">
        </el-table-column>
        <el-table-column label="打款人"
                         prop="reviewer"
                         v-if="role(['administrator','manager'])"
                         min-width="120">
        </el-table-column>
        <el-table-column label="备注"
                         min-width="100">
          <template slot-scope="scope">
            <el-tooltip class="item"
                        v-show="scope.row.description"
                        effect="dark"
                        :content="scope.row.description"
                        placement="top">
              <el-button>
                <i :style="`color:${scope.row.description ? '#3278EB' : ''}`"
                   class="icon iconfont icon-wenhao"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="经办人"
                         prop="manager"
                         min-width="300">
        </el-table-column>
      </tablePage>
    </el-card>
    <passModal @cancleDialog="cancleDialog"
               @submitPassDialog="submitPassDialog"
               :passDialogVisible="passDialogVisible"></passModal>
    <refuseModal @submitRefuseDialog="submitRefuseDialog"
                 @cancleDialog="cancleDialog"
                 :refuseDialogVisible="refuseDialogVisible"></refuseModal>
    <wholeModal @submitWholeDialog="submitWholeDialog"
                @cancleDialog="cancleDialog"
                :wholeDialogVisible="wholeDialogVisible"></wholeModal>
  </div>
</template>

<script>
import withdrawCard from 'components/withdrawCard'
import brokerSelect from 'components/brokerSelect'
import belonger from 'components/belonger'
import tablePage from 'components/tablePage'
import passModal from './components/passModal'
import refuseModal from './components/refuseModal'
import wholeModal from './components/wholeModal'
import api from '@/api/index'
export default {
  components: {
    withdrawCard,
    brokerSelect,
    belonger,
    tablePage,
    passModal,
    refuseModal,
    wholeModal
  },
  data () {
    return {
      wholeDialogVisible: false,
      refuseDialogVisible: false,
      passDialogVisible: false,
      isAllWithDraw: true,
      formInline: {
        broker_id: null,
        broadcast_date: [],
        page: 1,
        status: null,
        broker: null,
        manager: null,
        type: null
      },
      tableData: [],
      loading: true,
      total: 10,
      id: 0
    }
  },
  created () {
    this.dateFn()
    this._initState()
    this.withdrawSwitch()
  },
  methods: {
    tableSortChange ({ prop, order }) {
      if (order) {
        this.formInline.order = `${prop},${order === 'descending' ? order.substr(0, 4) : order.substr(0, 3)}`
        this._initState()
      }
    },
    dateFn () {
      this.formInline.broadcast_date.push(this.$timeFormat(
        new Date().setTime(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        "yyyy-mm-dd"
      ))
      this.formInline.broadcast_date.push(this.$timeFormat(new Date().getTime(), "yyyy-mm-dd"))
    },
    async switchFn (val) {
      const params = {}
      !val ? params.type = 0 : params.type = 1
      const { status_code, message } = await api.withdrawSwitchApi(params)
      if (status_code === 200) {
        this.$message({
          type: 'success',
          message
        })
        this.wholeDialogVisible = false
      } else {
        if (typeof message === 'object') {
          Object.keys(message).forEach((item) => {
            this.$message.error({ message: message[item] });
          });
        } else {
          this.$message.error({ message });
        }
      }
    },
    async submitWholeDialog (val) {
      this.switchFn(val)
    },
    switchChange (val) {
      if (!val) {
        this.isAllWithDraw = false
        this.wholeDialogVisible = true
      } else {
        this.switchFn(val)
      }
    },
    submitPassDialog (reviewer_id) {
      this.passDialogVisible = false
      this.withdrawAsync({ id: this.id, status: 1, reviewer_id })
    },
    submitRefuseDialog (params) {
      this.refuseDialogVisible = false
      this.withdrawAsync({ id: this.id, status: 2, description: params.description })
    },
    refuseClick ({ withdraw_id }) {
      this.refuseDialogVisible = true
      this.id = withdraw_id
    },
    cancleDialog (params) {
      this[params] = false
      if (params === 'wholeDialogVisible') {
        this.isAllWithDraw = true
      }
    },
    async withdrawAsync (params) {
      const { status_code, message } = await api.withdrawApplyApi(params)
      if (status_code === 200) {
        this.$message({
          type: 'success',
          message
        })
        this._initState()
      } else {
        if (typeof message === 'object') {
          Object.keys(message).forEach((item) => {
            this.$message.error({ message: message[item] });
          });
        } else {
          this.$message.error({ message });
        }
      }
    },
    passClick ({ withdraw_id }) {
      this.passDialogVisible = true
      this.id = withdraw_id
    },
    async withdrawSwitch () {
      const { status_code, message: { opened } } = await api.withdrawSwitchOpenedApi()
      if (status_code === 200) {
        this.isAllWithDraw = opened === '1' ? true : false
      }
    },
    async _initState (params = this.formInline) {
      const { status_code, message, meta: { pagination: { total } } } = await api.withdrawListApi(params)
      if (status_code === 200) {
        this.tableData = message
        this.total = total
        this.loading = false
      }
    },
    selectTreeChange (id) {
      this.formInline.broker_id = id
    },
    clearBrokerSelect () {
      this.formInline.broker_id = ''
    },
    exportClick () {
      this.$excelDownload(this.formInline, "api/withdraw/broker/export");
    },
    belongerSeChange (id) {
      this.formInline.manager = id
    },
    searchClick () {
      this.formInline.page = 1
      this._initState()
    },
    currentChange (page) {
      this.formInline.page = page
      this._initState()
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
h6 {
  font-size: 20px;
  color: #333333;
  line-height: 22px;
  font-weight: 500;
}
.btn {
  line-height: 20px;
  font-size: 16px;
  color: #3278eb;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
.switchStyle {
  margin-bottom: 4px;
  margin-left: 12px;
}
.switchStyle /deep/ .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
  font-weight: normal;
}
.switchStyle /deep/ .el-switch__label--left {
  z-index: 9;
  left: 20px;
}
.switchStyle /deep/ .el-switch__label--right {
  z-index: 9;
}
.switchStyle /deep/ .el-switch__label.is-active {
  display: block;
}
.switchStyle.el-switch /deep/ .el-switch__core,
.el-switch /deep/ .el-switch__label {
  width: 114px !important;
}
.switchStyle /deep/ .el-switch__label * {
  font-size: 12px;
}
.pass {
  color: #3278eb;
  display: inline-block;
  border-right: 1px solid #e3e3e3;
  padding-right: 10px;
  margin-right: 10px;
}
.refuse {
  color: #ff0029;
}
.pass,
.refuse {
  line-height: 14px;
  cursor: pointer;
}
.status-wrap {
  margin-top: 5px;
}
.pass_yet,
.refuse_yet,
.pass_loading {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 8px;
}
.pass_yet {
  background: rgba(80, 157, 237, 0.1);
  color: #3278eb;
}
.refuse_yet {
  color: #ff0029;
  background: rgba(255, 44, 56, 0.1);
}
.pass_loading {
  color: #ffd61f;
  background: rgba(248, 184, 0, 0.1);
}
.income {
  display: inline-block;
  margin-top: 5px;
}
</style>